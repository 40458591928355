.landing-page-first-div-1 {
    background-image: url(../assets/imgs/banner-bg.jpg);
    background-size: cover;
    background-position: -90px 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    /* For better positioning of child elements  or animation*/
    scroll-behavior: smooth;

}
.landing-page-first-grid-div-1 {
    display: grid;
    grid-template-columns: 50% 25% auto;
    flex-grow: 1;
}
.join-us-text-div-1 {
    color: #FFFF;
    font-size: 26px;
    font-family: CustomFontMedium;
    line-height: 39px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    display: inline-block;

}


@keyframes slide-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.text-parent-div-1-pull {
    padding: 0 0 0 10%;
   
}

.text-parent-div-1-pull.animate-slide-up {
    /* animation: slide-up 1s ease forwards; */
}

/* .text-parent-div-1-pull-2 {
    padding: 0 12%;
  } */

@keyframes slide-up {
    from {
        transform: translateY(100%);
        /* Start from below */
        opacity: 0;
        /* Invisible */
    }

    to {
        transform: translateY(0);
        /* Move to original position */
        opacity: 1;
        /* Fully visible */
    }
}

.text-parent-div-1-pull-2 {
    padding: 0 12%;

}







.landing-page-first-div-2 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


@media (min-width: 900px) and (max-width: 1100px) {
    .landing-page-first-div-1 {
        min-height: auto;
    }
    .landing-page-first-div-2 {   
        min-height: auto;
    }
    .landing-page-first-grid-div-1 {
        display: grid;
        grid-template-columns: 45% 25% auto;
        flex-grow: 1;
    }
}




.tdf-landing-page-text-div-1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .text-parent-div-1-pull {
    padding: 0 12%;
} */
.pull-your-score-text {
    color: #FFFF;
    font-size: 64px;
    font-family: CustomFontMedium;
    line-height: 77px;
    display: inline-block;

}
@media (min-width: 1201px) and (max-width: 1434px) {
    .pull-your-score-text {
        color: #FFFF;
        font-size: 50px;
        font-family: CustomFontMedium;
        line-height: 65px;
        display: inline-block;
    
    }
    .tdf-first-bottom-girl {
        transition: all 0.2s ease-in;
        max-width: 30px;
        position: absolute;
        right: 0;
        bottom: 0;
        /* z-index: 1031; */
    }
}

.Better-credit-health {
    color: #FFFF;
    font-size: 30px;
    font-family: CustomFontMedium;
    line-height: 40px;

}

.Better-credit-health-2 {
    color: black;
    font-size: 45px;
    font-family: CustomFontMedium;
    line-height: 50px;
    display: inline-block;

}

.pull-text-div-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.pull-text-div-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}



.join-us-text-div-2 {
    color: black;
    font-size: 26px;
    font-family: CustomFontMedium;
    line-height: 39px;
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    display: inline-block;

}

.enter-num-and-btn-div {
    display: flex;
    margin-top: 1.5rem;

}

.enter-num-and-btn-div input {
    height: 47px;
    width: 195px;
    box-shadow: 0 0 99px #00000029;
    border-radius: 17px;
    letter-spacing: 0px;
    text-transform: capitalize;
    background: #2a708c;
    color: #fff;
    border: 1px solid #FFFFFF;
    padding:0 15px 0 15px;
    font-size: 16px;
    font-family: CustomFontMedium;
    font-weight: 400;
}

.enter-num-and-btn-div-2 {
    display: flex;
}

.enter-num-and-btn-div-2 input {
    height: 47px;
    width: 195px;
    box-shadow: 0 0 99px #00000029;
    border-radius: 17px;
    letter-spacing: 0px;
    text-transform: capitalize;
    background: #FFFF;
    border: 1px solid black;
    padding:0 15px 0 15px;
    font-size: 16px;
    font-family: CustomFontMedium;
    font-weight: 400;
}


.enter-num-and-btn-div input::placeholder {
    color: white;
    opacity: 1;
    font-family: CustomFontMedium;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;



}

.enter-num-and-btn-div-2 input::placeholder {
    opacity: 1;
    font-family: CustomFontMedium;
    background: #fff;
    color: #000;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;


}

.enter-num-and-btn-div button {
    height: 52px;
    width: 195px;
    box-shadow: 0 0 99px #00000029;
    border-radius: 17px;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-size: 16px;
    font-family: CustomFontMedium;
    padding: .375rem .75rem;
    margin-left: 1rem;
    background-color: #FFFF;
    border: 1px solid transparent;
    cursor: pointer;


}

.enter-num-and-btn-div-2 button {
    height: 52px;
    width: 195px;
    box-shadow: 0 0 99px #00000029;
    border-radius: 17px;
    letter-spacing: 0px;
    text-transform: capitalize;
    font-size: 16px;
    font-family: CustomFontMedium;
    padding: .375rem .75rem;
    margin-left: 1rem;
    background-color: #044A67;
    color: #ffff;
    border: 1px solid #000;
    cursor: pointer;
    

}

.mobile-wrapper-div {
    height: 100%;
    display: flex;
    align-items: center;
    /* position: fixed */
}

.tdf-girl-have-mobile {
    /* position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1031; */
}

.tdf-first-bottom-girl {
    transition: all 0.2s ease-in;
    max-width: 380px;
    position: absolute;
    right: 0;
    bottom: 0;
    /* z-index: 1031; */
}

.tdf-first-bottom-girl-2 {}

.HappyTDFGirl-div-1 {
    height: 100%;
    display: flex;
    align-items: end;
}

.HappyTDFGirl-div {
    display: flex;
    align-items: end;
    justify-content: end;
}

.score-div-1 {
    position: absolute;
    margin-top: -349px;
    margin-left: 125px;
    text-align: center;
}

.score-div-11 {
    position: absolute;
    margin-top: -328px;
    margin-left: 110px;
    text-align: center;
}

.score-div-3 {
    position: absolute;
    margin-top: -345px;
    margin-left: 120px;
    text-align: center;
}

.score-div-2 {
    position: absolute;
    margin-top: -325px;
    margin-left: 107px;
    text-align: center;
}

.your-score-text {
    color: #FFFF;
    font-size: 12px;
    line-height: 20px;
    font-family: CustomFontMedium;
    font-weight: 400;
}

.your-score-count {
    color: #FFFF;
    font-size: 54px;
    line-height: 39px;
    font-family: CustomFontMedium;
    font-weight: 700;
    margin-top: 15px;
}

#TDFGirl {
    transition: opacity 0.3s ease-in-out;
}

.img-scr-1 {
    width: 300px;
    height: 547px;
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .join-us-text-div-1 {
        color: #FFFF;
        font-size: 18px;
        font-family: CustomFontMedium;
        line-height: 26px;
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        display: inline-block;
    
    }
}
@media (min-width: 0) and (max-width: 1300px) {
    .Better-credit-health-2 {
        color: black;
        font-size: 30px;
        font-family: CustomFontMedium;
        line-height: 40px;
        display: inline-block;
    
    }
    .join-us-text-div-2 {
        color: black;
        font-size: 18px;
        font-family: CustomFontMedium;
        line-height: 26px;
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        display: inline-block;
    
    }
}
@media (min-width: 1300px) and (max-width: 1600px) {
    .tdf-first-bottom-girl {
        max-width: 20%;
    }

}
@media (min-width: 1200px) and (max-width: 1300px) {
   
    .join-us-text-div-1 {
        color: #FFFF;
        font-size: 18px;
        font-family: CustomFontMedium;
        line-height: 26px;
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        display: inline-block;
    
    }
   
    .tdf-first-bottom-girl {
        max-width: 20%;
    }

    .img-scr-1 {
        width: 260px;
        height: auto;
    }
    .score-div-2 {
        position: absolute;
        margin-top: -285px;
        margin-left: 88px;
        text-align: center;
    }
    .score-div-3 {
        position: absolute;
        margin-top: -304px;
        margin-left: 98px;
        text-align: center;
    }
    

    .score-div-1 {
        margin-top: -305px;
        margin-left: 105px;
    }

    .score-div-11 {
        margin-top: -286px;
        margin-left: 90px;
    }

    .text-parent-div-1-pull {
        padding: 0 0 0 10%;
    }
  
}

@media (min-width: 900px) and (max-width: 1200px) {
    .pull-your-score-text {
        color: #FFFF;
        font-size: 44px;
        font-family: CustomFontMedium;
        line-height: 58px;
        display: inline-block;
    
    }
    .text-parent-div-1-pull {
        padding: 0 0 0 8%;
    }
    .score-div-2 {
        position: absolute;
        margin-top: -25%;
        margin-left: 7.5%;
        text-align: center;
    }
    .score-div-3 {
        position: absolute;
        margin-top: -26.5%;
        margin-left: 9%;
        text-align: center;
    }


}

@media (min-width: 900px) and (max-width: 1100px) {

    .pull-your-score-text {
        font-size: 35px;
        line-height: 44px;
    }

    .join-us-text-div-1 {
        font-size: 15px;
        line-height: 1.5rem;
    }

    .enter-num-and-btn-div button {
        height: 40px;
        width: 145px;
        font-size: 12px;
        white-space: pre;
    }

    .enter-num-and-btn-div input {
        height: 40px;
        width: 145px;
    }

    .enter-num-and-btn-div input::placeholder {
        color: white;
        opacity: 1;
        padding-left: 1rem;
        font-family: CustomFontMedium;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;


    }

    .join-us-text-div-2 {
        font-size: 15px;
    }

    .text-parent-div-1-pull-2 {
        padding: 0 0 0 8%;
    }

    .enter-num-and-btn-div-2 button {
        height: 40px;
        width: 145px;
        font-size: 12px;
        white-space: pre;
    }

    .enter-num-and-btn-div-2 input {
        height: 40px;
        width: 145px;
    }

    .enter-num-and-btn-div-2 input::placeholder {
        opacity: 1;
        padding-left: 1rem;
        font-family: CustomFontMedium;
        background: #fff;
        color: #000;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;


    }


}
.advertise-Section-div {
    position: relative;
    background-color: #FFFF;
}
.simple-slider-blog-post {
    padding: 0 83px;
    position: relative;
    background-color: #FFFF;
}
.home-page-faq-div {
    position: relative;
    background-color: #FFFF; 
    padding-bottom: 40px; 
}
.CustomizedInputBase-div-1 {
    padding-top: 40px;
    position: relative;
    background-color: #FFFF;
}
.FotterComponent {
    padding-top: 10px;
    position: relative;
    background-color: #FFFF;
}
@media (min-width: 0) and (max-width: 1200px) {
    .simple-slider-blog-post {
        padding: 0;
    }
}
@media (min-width: 900px) and (max-width: 1200px) {
    .tdf-first-bottom-girl {
        max-width: 25%;
    }

    .img-scr-1 {
        /* width: 260px; */
        width: 90%;
        height: auto;
    }

    .score-div-1 {
        margin-top: -107%;
        margin-left: 34%;
    }

    .score-div-11 {
        margin-top: -102%;
        margin-left: 29%;
    }
    .width-on-mobile-and-desk {
        width: 50%;
    }

}
.only-mobile-visibility-div-1 {
    display:none ;
}
.MyCarousel-1 {
    display: inline-block;
}

.WhyDueFactory-1 {
    background-color: #FFFF;
    position: relative;
}
.Top-Myths-about-credit-div {
    background-color: #FFFF;
    position: relative;
    z-index: 1;
    padding:  70px 20px;
}
.Top-Myths-about-credit-text {
    font-size:36px;
    font-weight: 600;
    font-family:CustomFontMedium;
    text-align: center;
 
    
}
.custom-button-div-1 {
    padding-top:40px ;
    background-color: #FFFF;
    position: relative;

}
.center-custom-btn-title-div {
    display: flex;
    justify-content: center;
}
.width-on-mobile-and-desk {
    width: 21%;
}
.titile-of-the-btn-div {
    font-family: CustomFontMedium;
    font-size: 24px;
    text-align: center;
    padding: none;
    font-weight: 500;

}

/* on mobile screens */

@media (min-width: 0) and (max-width: 900px) {
    .titile-of-the-btn-div {   
        padding: 0 10px;
        font-weight: 500;
    
    }
    .width-on-mobile-and-desk {
        width: 100%;
    }
    .custom-button-div-1 {
        padding:0 20px ;
    
    
    }
    .join-us-text-div-1 {   
        margin-bottom: 0; 
        font-size: 16px;     
        line-height: 26px;     
    }
    .MyCarousel-1 {
        display: none;
    }
    
    .only-mobile-visibility-div-1 {
        width: 100%;
        display: inline-block;
    }
    .text-parent-div-1-pull {
        padding: 0 12%;
       
    }

    .landing-page-first-grid-div-1 {
        display: grid;
        grid-template-columns: 100%;
        flex-grow: 1;
    }

    .grid-2 {
        display: none;
    }

    .grid-3 {
        display: none;
    }

    .landing-page-first-div-2 {
        display: none;
    }

    .text-parent-div-1-pull {
        padding: 0;
        width: 85%
    }

    .enter-num-and-btn-div {
        justify-content: center;
    }

    .pull-your-score-text {
        font-size: 35px;
        line-height: normal;
        text-align: center;
        font-weight: 500;
        display: inline;
    }

    .text-div-for-mobile {
        text-align: center;
    
    }



    .join-us-text-div-1 {
        text-align: center;
    }

    .we-take-care-mobile-div {
        text-align: center;
    }

    .enter-num-and-btn-div button {
        font-size: 12px;
        font-weight: 600;

    }

}

@media (min-width: 0) and (max-width: 600px) {
    .titile-of-the-btn-div {
        font-family: CustomFontMedium;
        font-size: 24px;
        text-align: center;
        padding: 0 10px;
        font-weight: 600;
    
    }
    .Top-Myths-about-credit-div {
        background-color: #FFFF;
        position: relative;
        z-index: 1;
        padding:  40px 20px;
    }
    .enter-num-and-btn-div {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 4%;
    }

    .enter-num-and-btn-div input {
        width: 100%;
        height: 38px;
        padding: 0 0 0 6px;
    }

    .enter-num-and-btn-div button {
        font-size: 12px;
        font-weight: 600;
        width: 100%;
        height: 40px;
    }

    .enter-num-and-btn-div input::placeholder {
        color: white;
        opacity: 1;
        padding-left: 0.7rem;
        font-family: CustomFontMedium;
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;


    }
    .enter-num-and-btn-div button {
        margin-left: 0;
    }
}
.mobile-img-score-657 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.MyCarousel-1 {
    background-color: #FFFF;
    position: relative;
}
