/* src/MyCarousel.css */

.carousel {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
}
@media (min-width: 1100px) and (max-width: 1300px) {
  
    .carousel {
        min-height: auto;
    }


}
@media (min-width: 900px) and (max-width: 1100px) {
 
    .carousel {
        min-height: auto;
    }
}

.carousel-slide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 10;
}

.prev, .next {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
}

.prev:hover, .next:hover {
    background: rgba(0, 0, 0, 0.7);
}
