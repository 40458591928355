
  /* Custom scrollbar styles for a specific container */
.modal-container ::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.modal-container ::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.modal-container ::-webkit-scrollbar-thumb {
  background: #1DB0E6; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.modal-container ::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar thumb */
}