.tdf-girl-hero-section {
    width: 85%;
    height: auto;

}
.tdf-hero-secton-div-1 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}


@media (min-width: 900px) and (max-width: 1200px) {
 
    .tdf-girl-hero-section {
        width: 75%;
        height: auto;

    }
    .tdf-hero-secton-div-1 {
        justify-content: end;
    }
   
}
@media (min-width: 900px) and (max-width: 1800px) {
 
    .hero-Section-animation-div-1{
        height: 100vh;
        overflow-y: scroll;
        scrollbar-width: none;
        
    }
   
}

  

