.list-tab .MuiTab-root {
  background-color: #ffffff;
  border-radius: 15px;
  color: #044A67;
}


.list-tab .MuiTab-root.Mui-selected {
  background-color: #044A67;
  color: #ffffff;
}

.selected {
  background-color: #044A67;
  border-radius: 15px;
  color: #fff;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 5px !important;
}

.css-46bh2p-MuiCardContent-root {
  padding: 5px !important;
  border-radius: 10px;

}

.dispute-div-1 {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.dispute-heading {
  font-size: 14px;
  font-family:Nunito Sans, sans-serif ;
  font-weight: 600;
  
}
.profile-modal-1 {
  font-size: 20px !important;
  font-family:Nunito Sans, sans-serif !important ;
  font-weight: 500 !important;
  line-height: normal;
}

.profile-modal-button-1 {
  background:#05517a;
  color: #fff;
  border-radius: 20px;
  font-family: Nunito Sans, sans-serif !important;  
  font-weight:500 !important ;
  padding: 5px 40px;
  margin-top: 20px;
  cursor: pointer;
}
.dispute-sub-heading {
  margin-top: 10px;
  font-size: 12px;
  font-family:Nunito Sans, sans-serif ;
  font-weight: 500;
}
.dispute-div-2{
  box-shadow: 1px 4px 10px 0px #8484841A;
  padding: 20px;
  border-radius: 10px;
}
.page-container-settle-1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.content-container-settle-1 {
  flex: 1; 
  padding: 30px;
}

.footer-grid-settle-1 {
  margin-top: auto; 
}
.settle-button-div-1 button {
color:#044a67 ;
border-color: #044a67;
padding: 7px 15px;
text-transform: capitalize;
margin-top: 15px;
height: 30px;
border-radius: 5px;
border: 0.5px solid #044a67;
background: transparent;
font-family:Nunito Sans, sans-serif ;

}

.settle-button-div-1 button:hover {
  border-color: #044a67;
  background-color: #044a67;
  color: #FFFF;
  cursor: pointer;


}
.something-1 {
  height: 100vh;
}
.profile-page-main-div-1 {
  padding: 32px;
}
@media screen and (max-width: 1100px) {
  .something-1 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


@media screen and (min-width: 1300px) {
  .dashborde-logo {
    max-width: 100%;
    width: 145px;
    position: relative;
    cursor: pointer;
    left: 50%;
  }
}
@media screen and (max-width: 1300px) {
  .dashborde-logo {
    max-width: 100%;
    width: 145px;
    position: relative;
    cursor: pointer;
    left: 25%;
  }
}
@media screen and (max-width: 1190px) {
  .dashborde-logo {
    max-width: 100%;
    width: 135px;
    position: relative;
    cursor: pointer;
    left: 25%;
  }
}
@media screen and (max-width: 600px) {
  .dispute-div-1 {
    display: flex;
    justify-content: center;
  }
  .content-container-settle-1 {
    padding: 20px;
  }
  .profile-page-main-div-1 {
    padding: 20px;
  }
  .profile-modal-1 {
    font-size: 16px !important;
  line-height: 1.5rem !important;
  }
  .profile-modal-button-1 {   
    padding: 3px 40px; 
  }


}

