.carousel-container {
    height: 90vh;
    display: flex;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    align-items: center;
    font-family: math;
  }
  
  .carousel-content {
    text-align: center;
    width: 100%;
    margin: 10px;
    padding: 20px;
    background-color: #fff;

    border-radius: 15px;
    /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); */
    transition: transform 0.3s ease;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0px;
    color: hsl(217, 19%, 35%);
  }
  
  .carousel-description {
    font-size: 18px;
    color: hsl(217, 19%, 50%);
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .carousel-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
  }
  
  .carousel-slide {
    display: flex;
    align-items: start;
    justify-content: space-evenly;
    gap: 0px;
    width: 100%;
    transition: all 0.5s ease;
    padding: 0px auto;
  }
  
  .carousel-image {
    max-height: 300px;
    object-fit: contain;
    border-radius: 10px;
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
  }
  
  .carousel-slide-description {
    color: hsl(217, 19%, 35%);
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    text-align: start;
  }
  .carousel-slide-description > span {
    font-size: 22px;
    color: blue;
    font-weight: 500;
  }
  .carousel-slide-description > p {
    font-size: 18px;
    color: rgb(30, 30, 30);
    font-weight: 500;
  }
  
  .carousel-arrow {
    font-size: 40px;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(217, 19%, 50%);
    z-index: 1;
    padding: 10px 20px;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .carousel-arrow:hover {
    color: white;
    background-color: hsl(217, 19%, 50%);
    border-radius: 50%;
  }
  
  .carousel-arrow.left {
    left: 20px;
  }
  
  .carousel-arrow.right {
    right: 20px;
  }
  
  .carousel-indicator {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .carousel-dot {
    width: 14px;
    height: 14px;
    margin: 0 6px;
    background-color: hsl(217, 19%, 70%);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .carousel-dot.active {
    background-color: hsl(217, 19%, 35%);
    transform: scale(1.2);
  }

  .tagline-text-div {
    font-size: 9px !important;
    font-family:"CustomFontMedium" !important;
  }
  .mobile-text-span{
    display: none;
  }
  @media (min-width: 0) and (max-width: 430px) {
.desktop-text-div-main {
  display: none;
}
.mobile-text-span{
  display: inline;
}
  }
  
  @media (max-width: 768px) {
    .carousel-image,
    .carousel-slide-description {
      max-width: 100%;
      font-size: 16px;
    }
  
    .carousel-content {
      padding: 15px;
    }
  
    .carousel-title {
      font-size: 32px;
    }
  
    .carousel-description {
      font-size: 18px;
    }
  
    .carousel-arrow {
      font-size: 30px;
    }
  }

  .fotter-text-div-tdf-112 {

    margin-left: 15px !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-family:CustomFontMedium !important;    
    white-space: pre;

    
  }
  .fotter-text-div-tdf-11 {

    cursor: pointer !important;
    font-size: 14px !important;
    font-family:CustomFontMedium !important;    
    white-space: pre;


    
  }
  .header-div-class-1 {
    margin-right: 70px;
  }
 
  
  @media (min-width: 0) and (max-width: 1000px) {
    .header-div-class-1 {
      margin-right: 0;
    }
  }
  @media (min-width: 900px) and (max-width: 1210px) {
    .fotter-text-div-tdf-112 {

      font-size: 14px !important;
      
    }
    .fotter-text-div-tdf-11 {

      font-size: 14px !important;
     
  
      
    }
  }
  .img-top-div-1 {
    /* text-align: center; */
    margin-left: 25px;
}
@media (min-width: 900px) and (max-width: 1200px) {
  /* after discussion with sumit's layout */
  .img-top-div-1 {
    text-align: center;
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
}
  @media (min-width: 0) and (max-width: 900px) {
    .fotter-text-div-tdf-112 {

      margin-left: 15px !important;
      cursor: pointer !important;
      font-size: 12px !important;
      font-family:CustomFontMedium !important;   
      
    }
    .fotter-text-div-tdf-11 {

      font-size: 12px !important;
     
  
      
    }
    .img-mobile-center-div {
      display: flex;
      justify-content: center;      
    }
 


}


  