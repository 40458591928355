.css-16pa2py {
    padding: 16px !important;
}
.icon-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    right: 10px;
  }
  
  .icon-wrapper svg {
    font-size: 16px !important; /* Force font size on SVG icons */
  }