@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* Basic Reset and Styling */
.class-main-primium-report * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* 
body,
html,
#root {
    height: 100%;
} */

.premium-report-body {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #ECF0F6;
}

.premium-report-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 10px 30px;
}

.premium-report-head>span {
    font-size: 1.3rem;
    font-weight: 600;
    font-family: 'Nunito Sans', sans-serif;
    text-shadow: rgb(0 0 0 / 31%) 1px 2px 14px;
}

.premium-report-head>p {
    font-size: .8rem;
    font-weight: 500;
    font-family: 'Nunito Sans', sans-serif;
}

.premium-report-first-dash {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 20px;
    gap: 1%;
    width: auto;
    height: 720px;
}

.premium-report-first-dash-1 {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 55%;
    /* height: 100%; */
    flex-direction: column;
    height: 700px;


}

.premium-report-first-dash-11 {
    /* height: 30vh; */
    height: 300px;
    width: 100%;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 10px;
}

.premium-report-first-dash-11>span {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.premium-report-first-dash-11>span>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 waitpx;
}

.premium-report-first-dash-11>span>div>p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    white-space: pre;
}

.premium-report-first-dash-11>p {
    width: 60%;
    height: 100%;
    font-family: 'Nunito Sans', sans-serif;
    padding: 10px 40px 0px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #606060;
}

.premium-report-first-dash-12 {
    /* height: 60vh; */

    height: 600px;
    width: 100%;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    background-color: white;
}

.premium-report-first-dash-12-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 20px 30px;
}

.premium-report-first-dash-12-head>span {
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

.premium-report-first-dash-2 {
    background-color: rgb(255, 255, 255);
    width: 44%;
    /* height: 100%; */
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    height: 700px;
}

.premium-report-first-dash-2-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 20px 30px;
}

.premium-report-first-dash-2-head>span {
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

.premium-report-first-dash-2-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
}

.premium-report-first-dash-2-chart-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    width: 100%;
}

.premium-report-first-dash-2-chart-1>div {
    font-size: .78rem;
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}

.premium-report-first-dash-2-chart-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.premium-report-first-dash-2-chart-2>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.premium-report-first-dash-2-chart-2>div>span {
    height: 5px;
    width: 60px;
    border-radius: 2px;
}

.premium-report-first-dash-2-chart-2>div>p {
    font-size: .78rem;
    padding: 0px 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}

.premium-report-first-dash-2-chart-3 {
    color: #606060;
    font-size: 14px;
    padding: 10px 20px;
    height: 290px;
    overflow: scroll;
}

.premium-report-first-dash-2-chart-3>p {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.premium-report-first-dash-12-chart-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 40px;
    gap: 10px;
    width: auto;

}

.premium-report-first-dash-12-chart-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

@media (max-width:450px) {
    .premium-report-first-Worth {}

    .premium-report-first-Worth>div>span {
        width: 5px !important;
    }

    .premium-report-first-Worth>div>p {
        font-size: .65rem !important;
    }
}

.premium-report-first-dash-12-chart-2>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.premium-report-first-dash-12-chart-2>div>span {
    height: 5px;
    width: 50px;
    border-radius: 2px;
}

.premium-report-first-dash-12-chart-2>div>p {
    font-size: .78rem;
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
}

.premium-report-first-dash-12-chart-3 {
    color: #606060;
    font-size: .8rem;
    padding: 20px 30px 20px 30px;
}

@media (min-width:1150px) and (max-width:1400px) {
    .premium-report-first-dash-2-chart-3 {
        font-size: 14px;
    }

    .premium-report-first-dash-11>p {
        font-size: 14px;
    }
}

@media (max-width:1149px) {
    .premium-report-first-dash {
        height: auto
    }

    .premium-report-first-dash {
        flex-direction: column;
    }

    .premium-report-first-dash-1 {
        width: 100%;
        height: auto;
        gap: 10px;
    }

    .premium-report-first-dash-2 {
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .premium-report-first-dash-12 {
        height: auto;
    }
}

@media (max-width:580px) {
    .premium-report-first-dash {
        padding: 10px;
        gap: 10px;
    }

    .premium-report-first-dash-11 {
        height: auto;
        width: 100%;
        box-shadow: 1px 10px 45px 10px #c1c1c113;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }

    .premium-report-first-dash-11>p {
        width: auto;
        padding: 20px;
    }

    .premium-report-first-dash-12-chart-2>div>span {
        width: 20px;
    }

    .premium-report-first-dash-2-chart-2>div>span {
        width: 20px;
    }

    .premium-report-first-dash-1 {
        gap: 10px;
    }

    .premium-report-first-dash-2 {
        margin-top: 0px;
    }

    .premium-report-first-dash-12-chart-2>div {
        gap: 0px;
    }

    .premium-report-first-dash-12-chart-2>div>p {
        padding: 10px 5px;
    }

    .premium-report-first-dash-12-chart-1 {
        padding: 0px 15px;
    }

    .premium-report-first-dash-2-chart-3 {
        padding: 20px 20px 20px 20px;
    }

}

/* credit worthiness */
.credit-worth-body {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #ECF0F6;
    padding: 10px 20px;
}

.bad-account-msg {
    font-family: 'Nunito Sans', sans-serif;

}

.credit-worth-inner-body {
    background-color: rgb(255, 255, 255);
    height: auto;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    padding-bottom: 10px;
}

.credit-worth-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 20px 30px;
}

.credit-worth-head>span {
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

.credit-worth-chart {
    padding: 10px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}


@media (max-width:580px) {

    .credit-worth-body {
        padding: 0px 10px;
    }

    .credit-worth-chart {
        padding: 0px 10px;
    }

    .premium-report-first-dash-11>span {
        width: 45%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: -30px;

    }

}

@media (min-width:581px) and (max-width:1149px) {
    .credit-worth-chart {
        padding: 0px 30px;
    }
}

.payment-reminder-main {
    margin: 0px 30px 20px 30px;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgb(255 64 63 / 19%) 0%, rgba(9, 9, 121, 0.21194415266106448) 12%, rgb(219 191 254 / 19%) 59%);
    border-radius: 10px;
    box-shadow: -5px 6px 31px 10px #4f4f4f0a;
}

.payment-reminder-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 10px 30px;
}

.payment-reminder-header>span {
    border-color: #e5e7eb;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Nunito Sans', sans-serif;
}

.payment-reminder-para {
    border-color: #e5e7eb;
    font-weight: 400;
    font-size: 14px;
    padding: 0px 30px;
    font-family: 'Nunito Sans', sans-serif;
    width: 70%;
    color: #3F3F3F;
}

.payment-reminder-blocks {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 0px;
    overflow: scroll;
    margin: 0px 20px;
}

.payment-reminder-block {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.779);
    min-width: 300px;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    transition: all .1s ease-in-out;
}

.payment-reminder-block:hover {
    cursor: pointer;
    box-shadow: -1px 5px 10px 0px rgba(99, 89, 99, 0.592);
}

.payment-reminder-block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 10px;
}

.payment-reminder-block-header>span {
    font-size: 12px;
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
}

.payment-reminder-block-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5%;
    padding: 0px 20px 10px 20px;
}

.payment-reminder-block-body-left {
    width: 15%;
}

.payment-reminder-block-body-left>img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: .4px solid rgb(142, 142, 142);
}

.payment-reminder-block-body-right {
    width: 80%;
}

.payment-reminder-block-body-due {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.payment-reminder-block-body-due>span {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    color: #676464;
}

.payment-reminder-block-body-due>p {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #676464;
}

.payment-reminder-block-body-amt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.payment-reminder-block-body-amt>span {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    color: #676464;
}

.payment-reminder-block-body-amt>p {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #676464;
}

.payment-reminder-view-all-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
}

.payment-reminder-view-all-btn {
    color: #1e1c1cee;
    font-size: 14px;
    font-weight: 500;
    height: 50px;
    width: 150px;
    background-color: rgba(255, 255, 255, 0.105);
    border-radius: 10px;
    border: .2px solid #d2d2d2;
    transition: all .1s ease-in-out;
}

.payment-reminder-view-all-btn:hover {
    cursor: pointer;
    border: .4px solid #d2d2d2;
    background-color: rgba(160, 160, 160, 0.185);
    color: #1e1c1ce1;
    box-shadow: -1px 1px 10px 0px rgba(99, 89, 99, 0.447);
}

@media (max-width:1000px) {
    .payment-reminder-para {
        width: 100%;
        font-size: 12px;
    }
}

@media (max-width:580px) {

    .payment-reminder-main {
        margin: 0px 10px 10px 10px;
    }

    .payment-reminder-header {
        padding: 15px 20px 10px 20px;
    }

    .payment-reminder-para {
        padding: 0px 20px;

    }

    .payment-reminder-blocks {
        flex-direction: column;
        margin: 0px 10px;
    }

    .payment-reminder-block {
        min-width: 250px;
        width: 100%;
    }

    .payment-reminder-view-all-btn {
        font-size: 12px;
        height: 30px;
        width: 110px;
    }
}


/* credit worthiness */
.custom-modal-main-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-scroll {
    overflow: hidden;
}

.payment-detail-main {
    /* width: 90%;
    background-color: #ECF0F6;
    border-radius: 24px;
    height: auto;
    max-height: 80vh; */


    width: 90%;
    background-color: #ECF0F6;
    border-radius: 24px;
    height: auto;
    max-height: inherit;
    padding-bottom: 20px;
}

.payment-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.payment-detail-header>span {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Nunito Sans', sans-serif;
}

.payment-detail-info {
    padding: 10px 20px;
}

.payment-detail-info>p {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    color: #655f5f;
    padding: 0px 10px;
    width: 70%;
}

@media (max-width:1000px) {
    .payment-detail-info>p {
        width: 100%;
        padding: 0px 0px;
    }

}




.payment-reminder-blocks-2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 20px;
}

@media (min-width:1050px) and (max-width:1400px) {
    .payment-reminder-blocks-2 {
        grid-template-columns: repeat(3, 1fr);
        padding: 20px;
    }
}

@media (min-width:600px) and (max-width:1049px) {
    .payment-reminder-blocks-2 {
        grid-template-columns: repeat(2, 1fr);
        padding: 20px;
    }
}

@media (max-width:599px) {
    .payment-reminder-blocks-2 {
        grid-template-columns: repeat(1, 1fr);
        padding: 20px;
        max-height: 50vh;
        height: auto;
        overflow: scroll;
    }
}


.payment-reminder-block-2 {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.779);
    min-width: 250px;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    transition: all .1s ease-in-out;
}

.payment-reminder-block-2:hover {
    cursor: pointer;
    box-shadow: -1px 5px 10px 0px rgba(99, 89, 99, 0.592);
}

.payment-reminder-block-header-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 10px;
}

.payment-reminder-block-header-2>span {
    font-size: 12px;
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
}

.payment-reminder-block-body-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5%;
    padding: 0px 20px 10px 20px;
}

.payment-reminder-block-body-left-2 {
    width: 15%;
}

.payment-reminder-block-body-left-2>img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: .4px solid rgb(142, 142, 142);
}

.payment-reminder-block-body-right-2 {
    width: 80%;
}

.payment-reminder-block-body-due-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.payment-reminder-block-body-due-2>span {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    color: #676464;
}

.payment-reminder-block-body-due-2>p {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #676464;
}

.payment-reminder-block-body-amt-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.payment-reminder-block-body-amt-2>span {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 400;
    color: #676464;
}

.payment-reminder-block-body-amt-2>p {
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #676464;
}

/* payment delinquencies */
.payment-del-main {
    padding: 20px 30px;
    width: auto;
    min-height: 250px;
    overflow-x: auto;
    /* Enables horizontal scrolling */
}

.delinquency-table {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
}



.delinquency-table table {
    width: 80%;
    /* Make the table take the full width */
    border-collapse: collapse;
    table-layout: fixed;
    /* Keeps column widths consistent */
}

.delinquency-table th,
.delinquency-table td {
    padding: 10px;
    text-align: center;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
    white-space: nowrap;
    /* Prevents wrapping of month names */
    width: 60px;
    border-right: 1px dashed #e9e9e9;
}

.delinquency-table td:first-child,
.delinquency-table th:first-child {
    position: sticky;
    left: 0;
    background-color: rgb(255 255 255);
    z-index: 1;
    color: #000000;
    min-width: 50px;
}

.delinquency-table th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    padding: 10px;
    /* Padding for month headers */
}

.delinquency-table tr {
    border-bottom: 1.5px dashed rgb(111 107 107 / 15%);
}

@media (max-width: 890px) {
    .delinquency-table {
        width: 100%;
        display: flex;
        overflow: auto;
        /* Ensure table is scrollable */
    }
}

@media (max-width: 600px) {
    .delinquency-table table {
        width: 100%;
    }

    .delinquency-table td,
    .delinquency-table th {
        padding: 5px;
        font-size: 10px;
        /* Smaller font size for mobile */
    }

    .payment-del-main {
        padding: 20px 10px;
        width: auto;
        min-height: 250px;
    }


}

.delin-elm-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delin-on-time {
    height: 20px;
    width: 40px;
    background-color: rgba(19, 211, 48, 0.82);
    border-radius: 10px;
}

.delin-amber {
    height: 20px;
    width: 40px;
    border-radius: 10px;
    background-color: #ffbf00c4;
}

.delin-late {
    height: 20px;
    width: 40px;
    background-color: #ff0000;
    border-radius: 10px;
}

.delin-no-status {
    height: 20px;
    width: 40px;
    background-color: rgba(19, 211, 48, 0.82);
    border-radius: 10px;
}

.delinquency-table th.sticky-corner {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: white;
    width: 50px;
    height: 50px;
}

/* payment delinquent */
.payment-del-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px 0px 30px;
    background-color: rgba(234, 229, 229, 0.248);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px #c1c1c136;
}

.payment-del-head>span {
    border-color: #e5e7eb;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
}



.payment-del-content-blocks {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    padding: 10px;
}

.payment-del-content-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px;
    flex-direction: column;
}

.payment-del-content-block>p {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
}

.payment-del-content-block>span {
    width: 30px;
    height: 30px;
    /* background-color: green; */
    border-radius: 10px;
}

.payment-del-head-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.payment-del-head-btn>button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background: transparent !important;
    border: 0px !important;
}

.payment-del-head-btn>p {
    font-weight: 500;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
}

.class-btn-active {
    color: #1c6ef3;
    stroke-width: 2px;
    font-size: 14px;
}

.class-btn-inactive {
    color: #585859;
    stroke-width: 2px;
    font-size: 14px;
}

.class-blank {
    background-color: rgb(211 211 211 / 46%);
}

.class-on-time {
    background-color: rgba(19, 211, 48, 0.821);
}

.class-late {
    background-color: rgba(255, 0, 0, 0.834);
}

.class-amber {
    background-color: #ffbf00c4;
}

.credit-worth-top-para {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
    padding: 0px 30px 20px;
    color: #606060;
    width: 70%;
}

.payment-del-head-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-del-head-2-span {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
    background-color: rgb(253 253 253 / 39%);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px #c1c1c136;
    width: 80%;
    color: #000000;
    border: .1px solid #80808026;
    font-weight: 500;
}

@media (max-width:900px) {
    .credit-worth-top-para {
        width: auto;
    }
}

/* gauge */


.gauge-wrapper {
    display: flex;
    width: 100%;
    padding: 0px;
    align-items: center;
    justify-content: center;
}

.gauge {
    /* background: #e7e7e7; */
    /* box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.50); */
    width: 260px;
    height: 140px;
    border-radius: 150px 150px 10px 10px !important;
    position: relative;
    overflow: hidden;
}

.gauge.min-scaled {
    transform: scale(0.5);
}

.gauge-center {
    content: '';
    color: #fff;
    width: 60%;
    height: 60%;
    background: #363636;
    border-radius: 100px 100px 0 0 !important;
    position: absolute;
    box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
    right: 21%;
    bottom: 0;
    color: #fff;
    z-index: 10;
}

.gauge-center .label,
.gauge-center .number {
    display: block;
    width: 100%;
    text-align: center;
    border: 0 !important;
}

.gauge-center .label {
    font-size: 0.75em;
    opacity: 0.6;
    margin: 20px 0px 0px;
}

.gauge-center .number {
    font-size: 1.2em;
}

.needle {
    width: 110px;
    height: 7px;
    background: #15222E;
    border-bottom-left-radius: 100% !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 100% !important;
    border-top-right-radius: 5px !important;
    position: absolute;
    bottom: -2px;
    left: 20px;
    transform-origin: 100% 4px;
    transform: rotate(0deg);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
    display: none;
    z-index: 9;
}

.four.rischio1 .needle {
    animation: fourspeed1 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio2 .needle {
    animation: fourspeed2 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio3 .needle {
    animation: fourspeed3 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.four.rischio4 .needle {
    animation: fourspeed4 2s 1 both;
    animation-delay: 1s;
    display: block;
}

.slice-colors {
    height: 100%;
}

.slice-colors .st {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border: 70px solid transparent;
}

.four .slice-colors .st.slice-item:nth-child(2) {
    border-top: 70px #f1c40f solid;
    border-right: 70px #f1c40f solid;
    background-color: #c50000;

}

.four .slice-colors .st.slice-item:nth-child(4) {
    left: 50%;
    border-bottom: 70px #4caf50 solid;
    border-right: 70px #4CAF50 solid;
    background-color: #e67e22;

}

@keyframes fourspeed1 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(16deg);
    }
}

@keyframes fourspeed2 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(65deg);
    }
}

@keyframes fourspeed3 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(115deg);
    }
}

@keyframes fourspeed4 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(164deg);
    }
}

/* @media (min-width:1100px) and (max-width:1150px) {
    .padding-new-class {
        padding-left: 50px;
    }
} */


.custom-tooltip {
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 10px;
    border-radius: 8px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    line-height: 1.5;
}

.custom-tooltip .highlight {
    color: #90CD4F;
    /* or #FF4040 for red */
    font-weight: bold;
}



.del-show-more-btn {
    background-color: transparent;
    color: #2c2a4a;
    font-family: 'Nunito Sans', sans-serif;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    font-size: 14px;
}

.del-show-more-btn:hover {
    font-size: 14.2px;
}


/* enquiry velocity */

.enquiry-vel-main {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #ECF0F6;
    padding: 10px 20px;
}

.enquiry-vel-main-inner {
    background-color: rgb(255, 255, 255);
    height: auto;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    padding-bottom: 10px;
}

.enquiry-vel-main-inner-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 20px 30px;
}

.enquiry-vel-main-inner-head>span {
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

.enquiry-vel-main-inner>p {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
    padding: 0px 30px 20px;
    color: #606060;
    width: 70%;
}

.enquiry-vel-main-inner-graph {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* credit mix css */
.credit-mix-main {
    font-family: 'Nunito Sans', sans-serif;
    background-color: #ECF0F6;
    padding: 10px 20px;

}

.credit-mix-inner {
    background-color: rgb(255, 255, 255);
    height: 850px;
    box-shadow: 1px 10px 45px 10px #c1c1c113;
    border-radius: 24px;
    padding-bottom: 10px;
}

.credit-mix-head {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 30px 20px 30px;

}

.credit-mix-head>span {
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

.credit-mix-nav {
    padding: 0px 20px;
    height: auto;
}

.credit-mix-nav>ul {
    list-style: none;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    background-color: #F2F2F2;
    border-radius: 14px;
    width: fit-content;
    padding-inline-start: 0px;
    /* padding: 10px 15px; */
}

.credit-mix-nav>ul>li {
    font-size: 12px;
    color: #2C2A4A;
    font-weight: 400;
    position: relative;
    z-index: 1;
    padding: 10px 25px;
}

.credit-mix-nav>ul>li:hover {
    cursor: pointer;
    opacity: .9;
    font-weight: 500;
}

.credit-mix-nav-active {
    background-color: #2C2A4A;
    color: #ffffff !important;
    height: 34px;
    /* width: 155px; */
    position: absolute;
    border-radius: 13px;
    z-index: 0;
    opacity: 1;
    margin-top: 1px;
}

.credit-mix-nav-active-text {
    color: white !important;
    font-weight: 600 !important;
}

.credit-mix-top-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 98%;
    gap: 1%;
    padding: 1%;
}

.credit-mix-top-cards>.credit-mix-top-card:nth-child(1) {
    box-shadow: none;
    border: none;
}

.credit-mix-top-card {
    flex: 1 1 calc(25% - 1rem);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 200px;
    max-width: 25%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.credit-mix-top-card:hover {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.credit-mix-top-card-head {
    font-size: 16px;
    font-weight: 600;
    color: black;
    padding: 10px 20px;
}

.credit-mix-top-card-footer {
    padding: 10px 30px;
}

.credit-mix-top-card-footer>span {
    font-size: 20px;
    font-weight: bold;
    color: black;

}

.credit-mix-top-card-footer>p {
    font-size: 10px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.credit-mix-top-card-chart {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    width: 98%;
    padding: 5px 2%;
}

.credit-mix-top-card-chart-left {}

.credit-mix-top-card-chart-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
}

.credit-mix-top-card-chart-right-child {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

}

.credit-mix-top-card-chart-right-child>span {
    font-size: 10px;
    font-weight: 400;
    margin-left: 25px;
}

.credit-mix-top-card-chart-right-child>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.credit-mix-top-card-chart-right-child>div>span {
    width: 20px;
    height: 8px;
    border-radius: 5px;
}

.credit-mix-top-card-chart-right-child>div>p {
    font-size: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.chart-inner-trend {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    position: absolute;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
    .credit-mix-top-card {
        flex: 1 1 calc(50% - 1rem);
        /* Two cards per row on smaller screens */
        height: 80px;
        /* Adjust height for smaller screens */
    }
}

@media (max-width: 480px) {
    .credit-mix-top-card {
        flex: 1 1 calc(100% - 1rem);
        /* One card per row on the smallest screens */
        height: 70px;
        /* Adjust height further */
    }
}

/* score gauge */


.new-score-gauge {
    height: 160px;
    width: 200px;
    margin-top: 20px;
}

.score-gauge-arc-1 {
    position: absolute;
    transform: rotate(-132deg);
    --b: 7px;
    --a: 264deg;
    /* decreing the width from 150px to 142px */
    width: 142px;
    aspect-ratio: 1;
    padding: var(--b);
    border-radius: 50%;
    /* background: #ff0000bd; */
    background: linear-gradient(240deg, rgba(245, 17, 17, 1) 20%, rgba(245, 237, 17, 0.5508797268907564) 62%, rgba(20, 165, 70, 110) 85%);
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 100%, #0000);
    mask: top var(--_g), calc(50% + 50%* sin(var(--a))) calc(50% - 50%* cos(var(--a))) var(--_g), linear-gradient(#0000 0 0) content-box intersect, conic-gradient(#000 var(--a), #0000 0);
}

.score-gauge-arc-2 {
    position: absolute;
    transform: rotate(-7deg);
    --b: 7px;
    --a: 40.333deg;
    width: 142px;
    aspect-ratio: 1;
    padding: var(--b);
    border-radius: 50%;
    background: #f4f447cf;
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 100%, #0000);
    mask: top var(--_g), calc(50% + 50%* sin(var(--a))) calc(50% - 50%* cos(var(--a))) var(--_g), linear-gradient(#0000 0 0) content-box intersect, conic-gradient(#000 var(--a), #0000 0);
}

.score-gauge-arc-3 {
    position: absolute;
    transform: rotate(40deg);
    --b: 7px;
    --a: 40.333deg;
    width: 142px;
    aspect-ratio: 1;
    padding: var(--b);
    border-radius: 50%;
    background: #ffa500e6;
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 100%, #0000);
    mask: top var(--_g), calc(50% + 50%* sin(var(--a))) calc(50% - 50%* cos(var(--a))) var(--_g), linear-gradient(#0000 0 0) content-box intersect, conic-gradient(#000 var(--a), #0000 0);
}

.score-gauge-arc-4 {
    position: absolute;
    transform: rotate(87deg);
    --b: 7px;
    --a: 45.33deg;
    width: 142px;
    aspect-ratio: 1;
    padding: var(--b);
    border-radius: 50%;
    background: #8bf14c;
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 100%, #0000);
    mask: top var(--_g), calc(50% + 50%* sin(var(--a))) calc(50% - 50%* cos(var(--a))) var(--_g), linear-gradient(#0000 0 0) content-box intersect, conic-gradient(#000 var(--a), #0000 0);
}


.new-score-gauge-circle {
    width: 140px;
    height: 140px;
    border-right: 70px solid #efefef88;
    border-top: 70px solid #efefef88;
    border-left: 70px solid #efefef88;
    border-bottom: 70px solid transparent;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    margin: 0;
    padding: 0;
}

.new-score-gauge-circle-1 {
    height: 100px;
    width: 120px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.new-score-gauge-circle-2 {
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-score-gauge-circle-2>span {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 800;
}

.new-score-gauge-circle-2>div {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.triangle-shape-needle {
    --gauge-width: 130px;
    --min: 0;
    --max: 100;
    --gauge-height: calc(var(--gauge-width) / 2);
    position: absolute;
    height: calc(var(--gauge-height) / 1.1);
    width: 20px;
    background: #15222e;
    transform-origin: bottom;
    transform: translate(0%, -50%) rotate(-124deg);
    transition: transform 1.5s ease-in-out;
    width: 20px;
    aspect-ratio: 1/cos(30deg);
    clip-path: polygon(50% 0, 100% 20%, 0 20%);
    background: linear-gradient(45deg, #B2A0A0, #b2a0a0be);
}

@keyframes rotate {
    0% {
        transform: translate(0%, -50%) rotate(-124deg);
    }

    100% {
        transform: translate(0%, -50%) rotate(124deg);
    }
}


.score-gauge-milstone-1 {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    transform: translate(-400%, 400%);
    transition: transform 1.5s ease-in-out;
}

.score-gauge-milstone-2 {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    transform: translate(-50%, -616%);
    transition: transform 1.5s ease-in-out;
}

.score-gauge-milstone-3 {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    transform: translate(350%, -430%);
    transition: transform 1.5s ease-in-out;
}

.score-gauge-milstone-4 {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    transform: translate(490%, -40%);
    transition: transform 1.5s ease-in-out;
}

.score-gauge-milstone-5 {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;
    transform: translate(400%, 400%);
    transition: transform 1.5s ease-in-out;
}





/* .new-score-gauge-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 waitpx;
}

.new-score-gauge-main>div>p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    white-space: pre;
} */

.new-score-gauge-main {
    width: 300px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-score-gauge-main * {
    box-sizing: border-box;
    margin: 0;
}

.new-score-gauge-main>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-score-gauge-main>div>p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    white-space: pre;
}