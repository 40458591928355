@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.top-bar-main {
    padding: 10px 20px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
}

.hamburger .line {
    width: 18px;
    height: 2px;
    background-color: #000000;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

.hamburger:hover {
    cursor: pointer;
}

#hamburger-9 {
    position: relative;
    transition: all 0.3s ease-in-out;
}

#hamburger-9.is-active {
    transform: rotate(45deg);
}


#hamburger-9.is-active .line {
    width: 16px;
}

#hamburger-9.is-active .line:nth-child(2) {
    opacity: 0;
}

#hamburger-9.is-active .line:nth-child(1) {
    transform: translateY(8px);
}

#hamburger-9.is-active .line:nth-child(3) {
    transform: translateY(-6px) rotate(90deg);
}

.breadcrumb-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
}

.breadcrumb-block-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/*  */

.top-bar-main-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.top-bar-main-right-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.top-bar-main-right-1>*:hover {
    background-color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.top-bar-main-right-1>img {
    width: 20px;
}