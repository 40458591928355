.grid-container {
  display: grid;
  grid-template-columns: (repeat(100%));
  padding: 5% 12%;
  gap: 2rem;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 .1rem 1rem #0000001a;
  border-radius: 1em;
  box-sizing: border-box;
  border: 1px solid #FFFF

}
.grid-item:hover {
  box-shadow: 0 1.1rem 1.2rem #0000001a;
    border: 1px solid #00000057;
}

.title {
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica;

}

.text-container {
  padding: 1rem 2rem 1rem 1rem;
}

.founderName {
  font-size: 24px;
  font-weight: 400;
  font-family: Helvetica;
  margin-top: 10px;
}

.detail {
  font-size: 14px;
  font-weight: 400;
  font-family: Helvetica;
  text-align: justify;
  line-height: 20px;
  margin-top: 10px;
  color: #212529;
}

/* 1300 tak ye 50% 50% lega , after 1300px these classes not work , work only large to 1300px \*/

@media screen and (min-width: 1300px) {
  .grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: #fff;
    
  }

  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
  }
}
@media screen and (max-width: 1300px) {
  .grid-container {
  padding: 5% 20%;   
  }

}
@media screen and (max-width: 1000px) {
  .grid-container {
  padding: 5% 10%;   
  }

}

@media screen and (min-width: 600px) {
  .card-founder {
    display: flex;
  }

  .img {
    padding: 10px;   
    display: flex;
    align-items: center;
  }
  .imgs {
    border-radius: 1em;
  }
}

/* Til 600px these classes work */
@media screen and (max-width: 600px) {
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;

  }
  .grid-container {
     padding:10% 10px;
     
  }
  .detail {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    letter-spacing: .0179em;
    line-height: 1.3rem;
  
  }
}