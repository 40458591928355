
.list-tab .MuiTab-root {
    background-color: #ffffff;
    border-radius: 15px;
    color: #044A67;
  }
  
  
  
  .list-tab .MuiTab-root.Mui-selected {
    background-color: #044A67;
    color: #ffffff;
  }
  .selected{
    background-color:  #044A67;
    border-radius: 15px;
    color: #fff;
  }
  .notSelected{
    background-color: #fff;
  } 
