.dashboard-layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #ffffff;
    overflow: hidden;
}

.dashboard-layout__left {
    width: 280px;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.3s ease;
}

@media (max-width:900px) {
    .dashboard-layout__left {
        position: absolute;
        transition: width 0.3s ease;
    }

}

.dashboard-layout__right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease;
    background-color: #ffffff;
}
