/* carouselStyles.css */
.slick-slide.slick-active.slick-current {
    display: flex;
    justify-content: center;
}

.slick-slide.slick-active {
    display: flex;
    justify-content: center;
}

/* .slick-slider{
    padding: 0 73px;
}
@media (max-width: 767px) {
    .slick-slider {
        padding: 0;
    }
} */

.nextArrow,
.prevArrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    background-color: #044a67;
    /* Change this to your preferred background color */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* Makes the background circular */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nextArrow {
    right: -35px;
    transform: translateY(-50%);
}

.prevArrow {
    left: -35px;
    transform: translateY(-50%);
}

/* Media Queries */
@media (min-width: 0) and (max-width: 1100px) {

    .nextArrow,
    .prevArrow {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .prevArrow {
        left: 15px;
    }

    .nextArrow {
        right: 15px;
    }
}

@media (min-width: 580px) and (max-width: 769px) {
    .prevArrow {
        left: 10px;
    }

    .nextArrow {
        right: 10px;
    }
}

@media (min-width: 500px) and (max-width: 580px) {
    .prevArrow {
        left: 20px;
    }

    .nextArrow {
        right: 20px;

    }
}

@media (min-width: 0) and (max-width: 500px) {
    .prevArrow {
        left: 15px;
    }

    .nextArrow {
        right: 15px;

    }
}