.grievance-div-bg {
    /* background: rgb(248, 248, 248); */
    padding: 60px 10% ;   
}

.grievance-div-1 {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 21.82px;

   
}
.grievance-div-text {
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.1px;    
    margin-top: 30px;

}
.grievance-highlight-text {

    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.1px;

}
.grievance-faq-sub {
    margin-top: 30px;
}
.grievance-faq-sub-1{
    padding: 0 10%;
}
.office-add-tdf {
    display: flex;
}


@media (min-width: 0) and (max-width: 580px) {
 
    .grievance-div-1 {
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.82px;
       
    }
   
}

@media (min-width: 600px) and (max-width: 970px) {
 
    .grievance-faq-sub-1{
        padding: 0 5%;
    }
   
}
@media (min-width: 0) and (max-width: 600px) {
 
    .grievance-faq-sub-1{
        padding: 0 1%;
    }
    ul {
        padding-left: 20px;
    }
    .office-add-tdf {
        display: block;
    }
    .grievance-div-bg {
    padding: 30px 5% ;   


    }
}

.page-container-grievance-1 {
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
  }
  
  .content-container-grievance-1 {
    flex: 1; 
  }
  
  .footer-grid-grievance-1 {
    margin-top: auto; 
  }



