@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');



.landing-page-main {
    padding: 10px 20px;
    font-family: 'Nunito Sans', sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-page-common {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 75%;
    animation: width .6 ease;
}