@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.home-dash-sidebar {
    background-color: #ffffff;
    padding: 10px 10px;
    border-radius: 14px;
    box-shadow: 0 0 10px rgb(160 160 160 / 30%);
    transition: width 0.3s ease;
    height: 95.6vh;
    margin: 10px;
    width: 240px;
    font-family: 'Nunito Sans', sans-serif;
}

@media (max-width: 1200px) and (min-width: 900px) {
    .home-dash-sidebar {
        width: 40px;
    }
}

@media (max-width: 900px) {
    .home-dash-sidebar {
        width: 240px;
        position: absolute;
        z-index: 1;
        display: none;
    }
}

.home-dash-sidebar-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.home-dash-sidebar-head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
}

.home-dash-sidebar-head>img {
    width: auto;
    height: 30px;
    margin-top: 10px;
}

.home-dash-sidebar-head>button {}

.home-dash-sidebar-body {
    flex-grow: 1;
    overflow-y: hidden;
    padding: 10px 0px;
    width: 100%;
}

.home-dash-sidebar-body-inner {
    width: auto;
    padding: 5px;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;


}

.home-dash-sidebar-body-inner>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 10px;
    width: 85%;
    border-radius: 10px;
    border: .5px solid #22aee500;
}

.home-dash-sidebar-body-inner>div:hover {
    cursor: pointer;
    background: #b4eaff40;
    border: .5px solid #22aee5bf;
}


.active-elem {
    background: #b4eaff40;
    border: .5px solid #22afe5;
}

.active-elem>span {
    font-size: 14px;
    color: #5d5757;
    font-family: 'Nunito Sans', sans-serif;
}

.home-dash-sidebar-body-inner>div>span {
    font-size: 14px;
    color: #5d5757;
    font-family: 'Nunito Sans', sans-serif;
    white-space: pre;
}

.home-dash-sidebar-btn {
    position: absolute;
    border: 0.5px solid #eeeeee;
    width: 25px;
    height: 40px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 240px;
    margin-top: 5px;
    font-size: 20px;
    background: #fafafa;
    clip-path: path("M 0 0 H 10 A 15 20 0 1 1 10 40 H 0 Z");
    transform: rotate(-180deg);
}

.home-dash-sidebar-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.home-dash-sidebar-footer-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
}

.home-dash-sidebar-footer-top>span {
    font-size: 14px;
    color: #5d5757;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
}

.home-dash-sidebar-footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.home-dash-sidebar-footer-bottom>p {
    font-size: 12px;
    color: #5d5757;
    font-family: 'Nunito Sans', sans-serif;
}

.home-dash-sidebar-footer-bottom>img {
    height: 60px;
}


.home-dash-sidebar-body-inner>div .hover-tooltip {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: 0s;
    margin-left: 50px;
    padding: 5px 10px;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.home-dash-sidebar-body-inner>div:hover .hover-tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}