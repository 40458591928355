@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.switch-container {
    position: relative;
    display: flex;
    background-color: #F2F2F2;
    border-radius: 30px;
    padding: 5px;
    width: fit-content;
    height: 30px;
    margin-bottom: 20px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

}
.loan-card-text-div {
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight:600 ;
    color:#000000C7;
}
.donut-chart-div-1 {
    position: relative;
     width: 100%;
      height: 180px; 
      display:flex;
       justify-content:space-around;
        align-items:center;
         background:#FFFF; 
         border-radius:24px;
         /* min-width:300px; */
        /* box-shadow: 0px 4px 4px 0px #00000040; */

}

.chartTopDiv-1 {
    width: 140px;
    padding: 10px;
}

.switch-btn {
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    color: #555;
    z-index: 1;
    /* To keep the text above the background slider */
    font-size: 12px;
    font-weight: 500;
    font-family: 'Nunito Sans', sans-serif;
    white-space: pre;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-details-div-1 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5rem;
    opacity: 70%;
}

.card-details-div-2 {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5rem;
}

.switch-btn.active {
    color: white;  
}

.section-heading-1 {
    font-size: 16px;
    font-family: 'Nunito Sans', sans-serif;
    padding:0 5px 10px;
    font-weight: 600;
    color: #2C2A4AC7;
    border-bottom: 0.5px solid #D2D1D1;
}
.crcle-dot {
    height: 100px;
    width: 100px;
    /* background: #FFFFFF; */
    background: #EFEFEF78;

    border-radius: 50%;
    display: inline-block;
  }

.switch-background {
    position: absolute;
    top: 5px;
    left: 0;
    height: 100%;
    width: 33.33%;
    /* Equal width for each button */
    background-color: #34324a;
    /* Active background color */
    border-radius: 30px;
    transition: left 0.3s ease;
    /* Smooth sliding effect */
    z-index: 0;
    height: 75%;
}

.parentDiv1 {
    background-color: #FFFF;
    border-radius: 24px;

}
.top-div-pre {
        padding: 10px 20px 40px;
    
}

.topdiv {
    /* background-color: #ECF0F6; */
    /* padding: 20px; */
    display: grid;
    /* grid-template-columns: 25% 74%; */
    grid-template-columns: auto auto auto auto;

    gap: 10px;
    overflow: hidden;
    padding: 10px 20px;
    /* padding: 20px 40px; */
}

.childdiv {
    background-color: #FFFF;
    padding: 10px 32px;
    border-radius: 24px;

}

.childdiv2 {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    overflow: auto;
}

.cardchild {
    padding: 20px;
    width: 80%;
}


.card {
    /* background-color: #FFFF; */
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    height: 180px;




}

.imgsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    /* padding: 0 20px; */
    margin: 10px 0;
}

.textDiv {
    line-height: 20px;


}

.totalamount {
      font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    padding: 0 20px;
    /* line-height: 5px; */
}

.cardammount {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 800;
}

.tip {
    font-size: 10px;
    font-weight: 500;
    font-family: 'Nunito Sans', sans-serif;

}

.loanType {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    background-color: #FFFF;
    /* margin-top: 20px; */
    /* gap: 20px; */
    padding: 10px;
    border-radius: 24px;
}

.text-of-card {
    font-size: 14px;
      font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}

.text-div-2 {
    font-size: 10px;
      font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}

.text-div-amt {
    color: "#7CB342";
    font-size: 12px;
      font-family: 'Nunito Sans', sans-serif;
}

.Secured {

    padding:0 10px;
    /* border: 1px solid #D2D1D1; */
    border-radius: 10px;


}

.Unsecured {
    padding: 0 10px;
    /* border: 1px solid #D2D1D1; */
    border-radius: 10px;

}

.CreditCards {
    padding:0 10px;
    /* border: 1px solid #D2D1D1; */
    border-radius: 10px;
}

.SecuredCard {
    display: grid;
    grid-template-columns: 59% 39%;
    gap: 1%;
    border: "1px solid #D2D1D1";
    margin-top: 10px;



}

.Accinfo {
         border-radius: 5px;
    /* box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12); */
    padding: 0 10px;
    text-align: center;
    gap: 5px;
    /* background-color: #FFFF; */
}

.totalamtCard {
    border-radius: 5px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding:0 20px;
    display: grid;
    grid-template-columns: 100%;
}
.totalamtCardCredit {
    border-radius: 5px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    padding:0 20px;
    display: grid;
    grid-template-columns: 100%;
    /* background: radial-gradient(50% 50% at 50% 50%, rgba(144, 122, 214, 0.59) 0%, rgba(218, 191, 255, 0.59) 100%); */

}

.textcardDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;
}

.acctiveAccounts {
    background: #7CB34299;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

}

.text-account-num {
    font-weight: 700;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
}

.text-account-text {
    font-weight: 400;
    font-size: 12px;
    font-family: 'Nunito Sans', sans-serif;
}

.closedAccounts {
    background: #F4F4F496;
    /* background-color: aqua; */
    padding: 10px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);

}

.loancard {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #FFFF;
    border-radius: 10px; 
    padding: 20px;
    margin-bottom: 10px;
    overflow: auto;


}

.sStats-p-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #E6EDF5;
    width: fit-content;
    border-radius: 24px;
    margin: 5px 20px;
    position: relative;
    cursor: pointer;
    bottom: 10px;
}

.sStats-p-toggle-button {
    padding: 2px 20px;
    border-radius: 24px;
    background: none;
    border: none;
    outline: none;
    color: #0a1629;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    z-index: 1;
    position: relative;
    transition: color 0.3s ease;
}

.sStats-p-toggle-active {
    color: #fff;
    font-weight: 600 !important;
}

.sStats-p-toggle-slider {
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 50%;
    background-color: #3F8CFF;
    border-radius: 24px;
    transition: all 0.3s ease;
    z-index: 0;
    margin: 0px 0px;
}

@media (min-width: 900px) and (max-width: 1475px) {
    .topdiv {

        /* grid-template-columns: 49.5% 49.5%; */
        grid-template-columns:auto auto auto auto;
        gap: 1%;

    }
    /* .card {
        min-width: 300px;
    } */
}

@media (min-width: 1220px) and (max-width: 1300px) {
    /* .topdiv { */

        /* grid-template-columns: 100%; */
        /* grid-template-columns: 30% 70%; */
    /* grid-template-columns: 50% 50%; */



    /* } */

    .SecuredCard {
        grid-template-columns: 100%;


    }
    .totalamtCardCredit {
      
        padding:20px;
  
    
    }
    .totalamtCard {
             padding:20px;      
    }

    /* .card {
        min-width: 350px;
    } */
}
@media (min-width: 0) and (max-width: 1300px) {
    
    .totalamtCardCredit {
      
        padding:20px;
  
    
    }
    .totalamtCard {
             padding:20px;      
    }
}
@media (min-width: 900px) and (max-width: 1300px) {
    
  .Accinfo {
    padding: 10px 0 0 0;
  }
}


  

@media (min-width: 600px) and (max-width: 1220px) {
    .topdiv {
    padding: 10px 10px;


        /* grid-template-columns: 100%;         */
        /* grid-template-columns: 40% 60%; */
    /* grid-template-columns: auto auto auto auto; */


    }


}

@media (min-width: 900px) and (max-width: 1220px) {
    .SecuredCard {
        grid-template-columns: 100%;


    }

    /* .card {
        min-width: 350px;
    } */


}
@media (min-width: 1220px) and (max-width: 1620px) {  

    /* .card {
        min-width: 350px;
    } */


}

@media (min-width: 750px) and (max-width: 900px) {


    .topdiv {

        grid-template-columns: 49.5% 49.5%;
        gap: 1%;
    }

    .loanType {
        grid-template-columns: 100%;
    }

    .card {
        min-width: 300px;
    }

}
@media (min-width: 900px) and (max-width: 1200px) {


    .topdiv {

        grid-template-columns: 49.5% 49.5%;
        gap: 1%;
    }


}
@media (min-width: 0) and (max-width: 750px) {

    .topdiv {

        grid-template-columns: 100%;
    padding: 10px 10px;

    }

    .loanType {
        grid-template-columns: 100%;
    }

    .childdiv2 {
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        overflow: auto;
    }

    .imgsection {
        padding: 0;
    }

}
.switch-container-top {
    padding:0 10px;
}
@media (min-width: 0) and (max-width: 600px) {


    .loanType {
        grid-template-columns: 100%;
        padding: 30px 10px;
    }

    .SecuredCard {
        display: grid;
        grid-template-columns: 100%;
        /* gap: 1%; */



    }
    .Accinfo {
        border-radius: 5px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 20px;
        text-align: center;
        gap: 5px;
        background-color: #FFFF;
    }
    .Unsecured {
        padding: 5px;
  
    
    }
    .Secured {
        padding: 5px;

    }
    .CreditCards {
        padding: 5px;
    }

    .switch-container-top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0
    }

}

@media (min-width: 0) and (max-width: 400px) {



    .childdiv {
        padding: 10px 15px;

    }

    .chartTopDiv-1 {
        width: 135px;
    }



}
@media (min-width: 0) and (max-width: 450px) {


    .switch-btn {

        padding: 5px 10px;
        font-size: 10px;
        white-space: pre;



    }





}
@media (min-width: 0) and (max-width: 410px) {


    .donut-chart-div-1{
        min-width: auto;
    }





}
@media (min-width: 0) and (max-width: 580px) {


 .top-div-pre {
    padding: 10px;

}





}

.credit-mix-desktop-div-1{
    padding: 20px;
    border-color: #e5e7eb;
    font-weight: 600;
    font-size: 18px;
}

@media (min-width: 900px) and (max-width: 1100px) {
    .credit-mix-desktop-div-1{
        padding: 20px;
        border-color: #e5e7eb;
        font-weight: 400;
        font-size: 18px;
    }
    .loan-card-text-div {
        font-size: 10px;
        font-family: 'Nunito Sans', sans-serif;
        font-weight:500 ;
        color:#000000C7;
    }
}
/* .css-15v22id-MuiAccordionDetails-root {
    padding: 0 !important;
} */
