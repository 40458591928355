 
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family:"CustomFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none; 
}
* p{
  padding: 0;
  margin: 0;
} 
* h1 {
  padding: 0;
  margin: 0;
  font-weight: normal;

}

code {
  font-family:'CustomFont';
}

@font-face {
  font-family: 'CustomFont';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CustomFontLight';
  src: url('./assets/fonts/Gilroy-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'CustomFontMedium';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'CustomFontRegular';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
}

.hide-scrollbar {
  overflow: auto; /* or overflow-y: auto; */
  scrollbar-width: none; /* For Firefox */
}

