.css-1m9128y {
    margin-top: 0px !important;
}
.crasoul-div-heading-112 {
    margin-top: 60px;
}

.desktop-site-height-div-1 {
    min-height: 100vh;
}

.desktop-site-div-112 {
    background: linear-gradient(to right, #044A67, #0a6b90);
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.tdf-div-123 {}

.img-div-112-tdf {
    padding: 20px 0 20px 0;
    width: 100%;

}
.num-of-carousel-div-112 {
    position: absolute;
    /* top: 100px; */
    right: 230px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.5;
    font-size: 800px; 
    color: rgba(178, 171, 171, 0.38); 
    font-weight: 600;
    text-align: center;
}
.crasoul-text-div-123 {
    font-size: 40px !important;
    color: #27498b !important;
    font-weight: 700 !important;
    font-family: "CustomFontMedium" !important;
}
.at-due-factory-text {
    font-size: 20px !important;
    color: #8c8080;
    font-family: "CustomFontMedium"  !important;
    line-height: normal;
}


@media (min-width: 900px) and (max-width: 1100px) {
    .img-div-112-tdf {
        margin-top: 0;
        padding: 20px 0 20px 0;
        width: 55%;

    }
    .at-due-factory-text {
        font-size: 16px !important;
    }
   
    .crasoul-div-heading-112 {
        margin-top: 0;
    }
    .num-of-carousel-div-112 {
        position: absolute;
        /* top: 119px; */
        right: 126px;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0.5;
        font-size: 350px; 
        color: #b2abab61; 
        font-weight: 600;
        text-align: center;
    }
    
}
@media (min-width: 1100px) and (max-width: 1120px) {
    .crasoul-text-div-123 {
        font-size: 35px !important;
        color: #27498b !important;
        font-weight: 700 !important;
        font-family: "CustomFontMedium" !important;
    }
}
@media (min-width: 931px) and (max-width: 1100px) {
    .crasoul-text-div-123 {
        font-size: 20px !important;
        color: #27498b !important;
        font-weight: 700 !important;
        font-family: "CustomFontMedium" !important;
    }
}
@media (min-width: 900px) and (max-width: 931px) {
    .crasoul-text-div-123 {
        font-size: 18px !important;
        color: #27498b !important;
        font-weight: 700 !important;
        font-family: "CustomFontMedium" !important;
    }
}
@media (min-width: 900px) and (max-width: 1100px) {
    .desktop-site-height-div-1 {
        min-height: auto;
    }

    .desktop-site-div-112 {
        height: auto;

    }

    .img-div-112-tdf {
        margin-top: 0;
        padding: 20px 0 20px 0;
      

    }
}